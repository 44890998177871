
import { computed, defineComponent, ref, watchEffect } from "vue"
import {
  IonPage, IonHeader, IonToolbar, IonContent, IonRow, IonCol, IonButton, alertController, IonCheckbox,
  IonCard, IonCardContent, IonCardTitle
} from '@ionic/vue'
import EntitySelect from '../components/EntitySelect.vue'
import TopBar from '../components/TopBar.vue'
import LoadingGif from '../components/Controls/LoadingGif.vue'
import MessageBar from '../components/Controls/MessageBar.vue'
import store from '@/store'
import { dateToString, currency } from '@/filters/texts'
import { BackendApi, getErrorText, getFullBackendUrl } from '../utils/http'
import { makeInvoice, Invoice, makeCredit, Credit } from '@/types/payments'
import { Entity, makeEntity } from '@/types/people'

export default defineComponent({
  name: 'InvoicesTab',
  components: {
    LoadingGif, EntitySelect, TopBar, MessageBar,
    IonHeader, IonToolbar, IonContent, IonPage, IonRow, IonCol, IonButton, IonCheckbox, IonCard, IonCardContent,
    IonCardTitle,
  },
  setup() {
    const loadingName = 'invoices-list'
    const creditsLoadingName = 'credits-list'
    const payLoadingName = 'payInvoice'
    const invoices = ref([])
    const allowCB = ref(false)
    const credits = ref([])
    const entity = ref(makeEntity())
    const selectedCreditAmount = ref(0)
    let selectedCredits: any = {}
    const blocked = ref(false)
    const delay = ref(false)


    function invoiceDate(invoice: Invoice): string {
      return dateToString(invoice.createdOn)
    }

    function creditDate(credit: Credit): string {
      return dateToString(credit.createdOn)
    }

    function asCurrency(amount: number): any {
      return currency(amount)
    }

    const allowPayWithCredits = computed(() => {
      return !(store.getters.options && store.getters.options.disablePayWithCredits)
    })

    function hasSelectedCredits(): boolean {
      return selectedCreditAmount.value > 0
    }

    function sumSelectedCredits(): number {
      let total = 0
      const creditsList: Credit[] = credits.value
      for (const credit of creditsList) {
        if (selectedCredits[credit.id]) {
          total += credit.remainingAmount
        }
      }
      return total
    }

    function toggleCreditSelection(credit: Credit) {
      selectedCredits[credit.id] = !selectedCredits[credit.id]
      selectedCreditAmount.value = sumSelectedCredits()
    }

    function isCreditSelected(credit: Credit): boolean {
      return selectedCredits[credit.id]
    }

    async function loadInvoices(entity: Entity) {
      if (entity && entity.id) {
        store.commit('startLoading', loadingName)
        const url = '/portal/api/entity/' + entity.id + '/invoices/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          const invoiceList = resp.data.map((elt: any) => makeInvoice(elt))
          const paymentUrls = invoiceList.map(
              (elt: Invoice) => elt.webPaymentUrl
          ).filter((elt: string) => !!elt)
          if (paymentUrls.length) {
            allowCB.value = true
          }
          invoices.value = invoiceList
        } catch (err) {
          await store.dispatch('addError', getErrorText(err))
        }
        store.commit('endLoading', loadingName)
      }
    }

    async function loadCredits(entity: Entity) {
      if ((entity) && (entity.id > 0)) {
        store.commit('startLoading', creditsLoadingName)
        const url = '/portal/api/entity/' + entity.id + '/credits/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          const creditsList = resp.data.map((elt: any) => { return makeCredit(elt) })
          credits.value = creditsList
          const creditsMap: any = {}
          for (const credit of creditsList) {
            creditsMap[credit.id] = true
          }
          selectedCredits = creditsMap
          selectedCreditAmount.value = sumSelectedCredits()
        } catch (err) {
          await store.dispatch('addError', getErrorText(err))
        }
        store.commit('endLoading', creditsLoadingName)
      }
    }

    function onEntityChanged(newEntity: Entity) {
      const effectiveChange = (entity.value.id !== newEntity.id)
      entity.value = newEntity
      if (effectiveChange) {
        loadInvoices(newEntity)
        loadCredits(newEntity)
      }
    }

    function refresh() {
      loadInvoices(entity.value)
      loadCredits(entity.value)
      store.commit('refreshInvoiceDone')
    }

    async function downloadInvoice(invoice: Invoice) {
      if (!delay.value && !blocked.value) {
        store.commit('startLoading', loadingName)
        try {
          const backendApi = new BackendApi('post', '/users/api/access-key/')
          const resp = await backendApi.callApi({ slug: '' + invoice.id, })
          const key = resp.data.key
          const fullUrl = getFullBackendUrl('/portal/facture/' + key + '/pdf/')
          const popup = window.open(fullUrl, '_blank')
          try {
            if (popup) {
              popup.focus()
              delay.value = true
              setTimeout(
                function() {
                  delay.value = false
                },
                15 * 1000
              )
            } else {
              blocked.value = true
            }
          } catch (e) {
            blocked.value = true
          }
        } catch (err) {
          await store.dispatch('addError', getErrorText(err))
        }
        store.commit('endLoading', loadingName)
        }
    }

    function getCreditAmount(invoice: Invoice): number {
      if (invoice.toBePaidPrice() > selectedCreditAmount.value) {
        return selectedCreditAmount.value
      } else {
        return invoice.toBePaidPrice()
      }
    }

    async function payInvoice(invoice: Invoice) {
      store.commit('startLoading', loadingName)
      try {
        const backendApi = new BackendApi('post', '/users/api/access-key/')
        const resp = await backendApi.callApi({ slug: '' + invoice.id, })
        const key = resp.data.key
        const fullUrl = getFullBackendUrl(invoice.getValidWebPayementUrl(key))
        window.open(fullUrl, '_blank')
      } catch (err) {
        await store.dispatch('addError', getErrorText(err))
      }
      store.commit('endLoading', loadingName)
    }

    async function doPayInvoiceWithCredit(invoice: Invoice, entity: Entity) {
      store.commit('startLoading', loadingName)
      try {
        const url = '/portal/api/entity-pay-with-credit/' + entity.id + '/' + invoice.id + '/'
        const backendApi = new BackendApi('post', url)
        const creditsArray: number[] = []
        const creditsList: Credit[] = credits.value
        for (const credit of creditsList) {
          if (selectedCredits[credit.id]) {
            creditsArray.push(credit.id)
          }
        }
        const resp = await backendApi.callApi({ credits: creditsArray, })
        if (resp.data.ok) {
          await store.dispatch('addSuccess', 'Le paiement a été effectué')
        } else {
          await store.dispatch('addWarning', 'Un problème est survenu')
        }
        refresh()
      } catch (err) {
        await store.dispatch('addError', getErrorText(err))
      }
      store.commit('endLoading', loadingName)
    }

    async function payInvoiceWithCredit(invoice: Invoice) {
      const total = sumSelectedCredits()
      if (total) {
        let message = '<div class="alert-line">Souhaitez-vous payer cette facture par avoir?</div><br />'
        if (total > invoice.toBePaidPrice()) {
          const futureCredit = currency(total - invoice.toBePaidPrice())
          message += '<div class="alert-line">Vous conserverez ' + futureCredit + ' en avoir suite à ce paiement.</div>'
        } else if (total < invoice.toBePaidPrice()) {
          const futurePayment = currency(invoice.toBePaidPrice() - total)
          message += '<div class="alert-line">' +
              'Le montant de l\'avoir est inférieur celui de la facture. ' +
              'Il restera ' + futurePayment + ' à payer.</div>'
        }
        const alert = await alertController
          .create({
            header: 'Payer par avoir',
            message: message,
            cssClass: 'pay-credit-alert',
            buttons: [
              {
                text: 'Annuler',
                role: 'cancel',
                cssClass: 'secondary',
                // handler: blah => {},
              },
              {
                text: 'Confirmer',
                handler: async() => {
                  await doPayInvoiceWithCredit(invoice, entity.value)
                },
              }
            ],
          })
        return alert.present()
      }
    }

    function areInvoicesLoading(): boolean {
      return store.getters.loading && store.getters.loading[loadingName]
    }

    function isPayLoading(): boolean {
      return store.getters.loading && store.getters.loading[payLoadingName]
    }

    function isLoading(): boolean {
      return areInvoicesLoading() || isPayLoading()
    }

    const invoiceRefreshed = computed(() => { return store.getters.invoiceRefreshed })

    watchEffect(
      () => {
        if (invoiceRefreshed.value) {
          refresh()
        }
      }
    )

    return {
      invoices, credits, entity, loadingName, creditsLoadingName, selectedCreditAmount, payLoadingName,
      onEntityChanged, invoiceDate, asCurrency, downloadInvoice, payInvoice, refresh, creditDate,
      payInvoiceWithCredit, hasSelectedCredits, sumSelectedCredits, toggleCreditSelection, isCreditSelected,
      areInvoicesLoading, isPayLoading, isLoading, invoiceRefreshed, getCreditAmount, allowCB, allowPayWithCredits,
      blocked, delay,
    }
  },
})
